import { Cell, StandardGrid } from '@components/grid';
import Layout, { Body, BodyWrapper } from '@components/layout';
import { media } from '@helpers/media_templates';
import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const LegalBody = styled(Body)`
  background: initial;
  ${media.small`
    padding-top: 20px;
    padding-bottom: 20px;
  `}
`;

const LegalPageWrapper = styled.div`
  li {
    font-family: primary-regular, sans-serif;
    line-height: 1.42;
    font-size: 15px;
    margin-bottom: 8px;

    ${media.medium`
      font-size: 18px;
    `}
  }

  table * {
    font-family: primary-regular, sans-serif;
  }

  td {
    vertical-align: top;
    margin-right: 10px;
    padding-bottom: 20px;

    :last-child {
      margin-right: 0;
      text-align: center;
    }
  }

  thead td {
    font-family: primary-regular, sans-serif;
    vertical-align: middle;
  }
`;

export default function LegalTemplate(props) {
  const {
    data: {
      markdownRemark: { html },
    },
    location,
  } = props;
  return (
    <>
      <Layout location={location}>
        <BodyWrapper>
          <LegalBody>
            <StandardGrid>
              <Cell>
                <LegalPageWrapper dangerouslySetInnerHTML={{ __html: html }} />
              </Cell>
            </StandardGrid>
          </LegalBody>
        </BodyWrapper>
      </Layout>
    </>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
    }
  }
`;
